html,
body {
    overflow: hidden;
}

body {
    margin: 0;
    font-family: "DT Flow", "Helvetica Neue", sans-serif;
    font-size: 14px;
    color: var(--text-color);

    &.mobile app-portal-panel .panel>div {
        flex-direction: column;
    }

    &.fatal-error {
        .main {
            display: none;
        }
    }
}

* {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: subpixel-antialiased;

    &[hidden] {
        display: none;
    }
}

iframe,
button {
    border: 0;
}

button {
    color: var(--text-color);
}

a {
    color: var(--link-text-color);
    text-decoration: none;
}

p {
    margin: 0;
}


.page-toolbar {
    display: flex;
    height: 40px;
    //border-bottom: solid 1.5px rgb(60, 105, 183);
    margin-bottom: 0;
    padding: 0 15px;
    //background: var(--table-header-background-color);
    position: relative;
    padding-right: 4.5px;
    //border-bottom-color: rgba(255, 255, 255, 0.82);
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    background: #636363;
    color: #FFF;

    .title {
        margin: 0;
        font: unset;
        font-family: "Poppins", Helvetica, Arial, sans-serif;
        font-variant-caps: all-small-caps;
        line-height: 20px;
        font-size: 20px;
        margin-top: -4px;
        text-align: left;
        padding-top: 0;
        z-index: 1;
        color: #FFF;

        &.larger {
            font-size: 20px;
            padding-top: 12px;
        }
    }
    .subtitle {
        font-size: 11px;
        line-height: 14px;
        opacity: 0.6;
    }

    .icon-btn {
        background: transparent;
        border: 0;
        cursor: pointer;
        transition: all 200ms ease-in-out;
        width: 34px;
        height: 34px;
        display: flex;
        justify-content: center;
        border-radius: 50%;
        position: relative;
        top: 2px;
        padding: 9px;
        filter: brightness(0.75) saturate(0.75);

        img {
            opacity: 1;
        }

        &:hover {
            opacity: 1;
        }
    }
}
