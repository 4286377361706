.cdk-overlay-backdrop.no-backdrop {
    background: #0000 !important;
}

// This will not listen to theme changes.
.dialog-termsOfUse .mat-dialog-container {
    background-color: #fff;
    color: #000;
    overflow: hidden;
    display: flex;
    padding: 0;
}

.dialog-terms {
    // max-height: calc(100% - 120px) !important;

    .mat-dialog-container {
        color: #000;
        max-width: 1004px;
        width: 100%;
        overflow: hidden;
        flex: 1;
        height: unset;
        padding: 0 !important
    }
}

.resizable-mat-dialog-panel {
    .mat-mdc-dialog-container {
        resize: both;
    }
}
.cdk-overlay-container .cdk-global-overlay-wrapper {

    .mat-mdc-dialog-container {
        outline: 0;
        border-radius: 6px;
        height: 100% !important;
        width: 100% !important;

        .mat-mdc-dialog-surface {
            padding: 0;
            color: var(--dialog-text-color, #fff);
        }
        &>div>div>ng-component {
            height: 100%;
        }
        .dialog-resizable {
            resize: both;
        }
    }

    .dialog-dark .mat-mdc-dialog-container {
        border-top: 2px solid;
        border-image: linear-gradient(90deg, #446ed5, #712f90) 100% 0 0 0/2px 0 2px 0 stretch;
    }
}

.dialog-draggable {
    box-shadow: 0 0 36px #00000091;
    border-radius: 9px;
}
.dark .dialog-draggable {
    box-shadow: 0 0 36px #a2e9ff91;
}

.cdk-overlay-pane.dialog-draggable {
    contain: content;
}

.cdk-overlay-pane.dialog-documentation {
    padding: 0;
    min-width: 90vw;
    min-height: 90vh;

    app-documentation {
        height: 90vh;
    }
}

.desktop {
    .dialog-padding .mdc-dialog__surface {
        padding: 24px !important;
    }


    .dialog-AskElevate {
        position: absolute !important;
    }

    &:not(.elevate).dialog-confirmation {
        .mat-mdc-dialog-container .mdc-dialog__surface {
            background-color: #fff;
        }
    }

    .dialog-draggable {
        position: absolute !important;
        // left: calc(50vw - 50%);
        // top: calc(50vh - 50%);

        will-change: transform;
    }
    .dialog-resizable {
        resize: both;
    }

    .dialog-nav-Profile .mat-mdc-dialog-container .mdc-dialog__surface {
        border-bottom-right-radius: 0;
    }
}

.dialog-claims-envselect .mat-mdc-dialog-surface,
.dialog-allocation-entry .mat-mdc-dialog-surface {
    padding: 0;
}

.mobile .cdk-overlay-pane {
    max-width: 100vw!important;
    max-height: 100vh!important;
}

.dialog-footer {
    background-color: #efefef44;
    text-align: right;
    height: 42px;
}

/**
 * Set an interaction delay on the backdrop element so that double-click
 * events can still trigger while an-instant dialog popup appears as an
 * intermediary
 */
.backdrop-delay-500ms {
    pointer-events: all;
    animation: monkeypatch-events 500ms linear;
}

@keyframes monkeypatch-events {
    0%,99% {
        pointer-events: none;
    }

    100% {
        pointer-events: all;
    }
}

// Custom style overrides for ngx ctx menu
ngx-ctx-menu {
    td.icon {
        padding-right: 4px;
    }
}


