// Color priorities for general use around pages
.rag-red {
    color: var(--palette-red-color);
    background-color: var(--palette-red-background-color);
}

.rag-amber {
    color: var(--palette-amber-color);
    background-color: var(--palette-amber-background-color);
}

.rag-green {
    color: var(--palette-green-color);
    background-color: var(--palette-green-background-color);
}

.rag-grey,
.rag-gray {
    color: var(--palette-gray-color);
    background-color: var(--palette-gray-background-color);
}

.rag-text-red {
    color: var(--palette-red-background-color);
}

.rag-text-amber {
    color: #926e00;
}

.rag-text-green {
    color: var(--palette-green-background-color);
}

.rag-text-grey,
.rag-text-gray {
    color: var(--palette-gray-background-color);
}

.dotrag {
    border-radius: 50%;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 4px;
}
