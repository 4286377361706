// From pmo/helpers.ts
.profile-image {
    width: 48px;
    height: 48px;
    position: relative;
    overflow: visible;

    img {
        width: 100%;
        height: 100%;
        border-radius: 33%;
        overflow: hidden;
        position: relative;

        // img pseudo elements aren't rendered unless the image is broken.
        // using this, we have a generic placeholder
        &::after {
            content: "";
            z-index: 1;
            position: absolute;
            inset: 0;
            background-color: #fff;
            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'><path d='M240-80v-172q-57-52-88.5-121.5T120-520q0-150 105-255t255-105q125 0 221.5 73.5T827-615l52 205q5 19-7 34.5T840-360h-80v120q0 33-23.5 56.5T680-160h-80v80h-80v-160h160v-200h108l-38-155q-23-91-98-148t-172-57q-116 0-198 81t-82 197q0 60 24.5 114t69.5 96l26 24v208h-80Zm254-360Zm-14 120q17 0 28.5-11.5T520-360q0-17-11.5-28.5T480-400q-17 0-28.5 11.5T440-360q0 17 11.5 28.5T480-320Zm-30-128h61q0-25 6.5-40.5T544-526q18-20 35-40.5t17-53.5q0-42-32.5-71T483-720q-40 0-72.5 23T365-637l55 23q7-22 24.5-35.5T483-663q22 0 36.5 12t14.5 31q0 21-12.5 37.5T492-549q-20 21-31 42t-11 59Z'/></svg>");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }

    &.tiny {
        width: 24px;
        height: 24px;
    }
    &.small {
        width: 30px;
        height: 30px;
    }

    .initials {
        user-select: none;
        height: 100%;
        width: 100%;
        text-align: center;
        padding-top: 8px;
        font-weight: bolder;
        overflow: hidden;

        &.noname {
            color: #ccc;
            background: #555;
        }

        &.variant-1 {color: #212121; background: #9dbb72;}
        &.variant-2 {color: #cdcdcd; background: #1f6cc4;}
        &.variant-3 {color: #212121; background: #e88113;}
        &.variant-4 {color: #212121; background: #9d72bb;}
        &.variant-5 {color: #212121; background: #ff56f7;}
        &.variant-6 {color: #212121; background: #d34d76;}
        &.variant-7 {color: #212121; background: #53ddd8;}
        &.variant-8 {color: #cdcdcd; background: #0b108f;}
        &.variant-9 {color: #cdcdcd; background: #0e501e;}
        &.variant-0 {color: #cdcdcd; background: #2c250f;}
    }

    .status-indicator {
        position: absolute;
        bottom: -2px;
        right: -2px;
        z-index: 1;
        width: 45%;
        height: 45%;
        min-height:10px;
        max-height:20px;
        min-width: 10px;
        max-width: 20px;
        border: 2px solid var(--background-color);
        border-radius: 50%;
    }
}

.dark .profile-image img::after {
    background-color: #000;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24' fill='%23ffffff'><path d='M240-80v-172q-57-52-88.5-121.5T120-520q0-150 105-255t255-105q125 0 221.5 73.5T827-615l52 205q5 19-7 34.5T840-360h-80v120q0 33-23.5 56.5T680-160h-80v80h-80v-160h160v-200h108l-38-155q-23-91-98-148t-172-57q-116 0-198 81t-82 197q0 60 24.5 114t69.5 96l26 24v208h-80Zm254-360Zm-14 120q17 0 28.5-11.5T520-360q0-17-11.5-28.5T480-400q-17 0-28.5 11.5T440-360q0 17 11.5 28.5T480-320Zm-30-128h61q0-25 6.5-40.5T544-526q18-20 35-40.5t17-53.5q0-42-32.5-71T483-720q-40 0-72.5 23T365-637l55 23q7-22 24.5-35.5T483-663q22 0 36.5 12t14.5 31q0 21-12.5 37.5T492-549q-20 21-31 42t-11 59Z'/></svg>");
}

.ngx-tooltip.br-12 {
    ngx-tooltip>.container {
        border-radius: 12px;
        --mdc-dialog-container-shape: 12px;
    }

    .mat-mdc-dialog-container .mdc-dialog__surface {
        border-radius: 12px;
    }
}
