/**
 * This file is _exclusively_ for Syncfusion global stylesheet changes.
 */
.e-popup.e-control {
    .e-ddl-header {
        .contractDropdownHeader {
            .type, .name, .date {
                font-weight: bold;
                display: inline-block;
                color: var(--text-color);
                padding: 4px 4px 0 8px;
                line-height: 24px;
            }
            .type { width:  32px;  }
            .name { width: 256px; }
            .date { width: 128px; }
        }
    }
    .e-dropdownbase {
        .contractDropdownItem {
            height: 26px;
            .type, .name, .date {
                display: inline-block;
                overflow: hidden;
                white-space: nowrap;
                padding: 6px 4px 0 0;
                line-height: 24px;
            }
            .type {
                width:  32px;
                height: 23px;
                padding: 0 4px 0 0;
                margin-left: -8px;

                mat-icon {
                    left: 0;
                    top: 6px;
                    position: absolute;
                    overflow: visible;
                }
            }
            .name { width: 256px; }
            .date { width: 128px; }
        }
    }
}

.e-splitter.e-splitter-horizontal .e-pane,
.e-splitter.e-splitter-vertical .e-pane {
    color: inherit;
}

.e-grid {
    height: 100%;

    .e-groupdroparea {
        background-color: var(--background-color);
        border-top-color: #292827;
        color: #a19f9d;
    }

    tr td.e-active {
        background-color: var(--table-row-selected-background-color);
    }
}

.e-calendar .e-content td.e-focused-date.e-today,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today {
    box-sizing: none;
}

div.e-tooltip-wrap.e-popup.legacy-tooltip {
    background-color: var(--tooltip-border-color);
    border-color: 1px solid var(--tooltip-border-color);
    border-radius: 6px;
}

.e-splitter {
    .e-split-bar {
        &.e-split-bar-horizontal .e-resize-handler {
            background: #0000 !important;

            &::before {
                content: "\e770" !important;
            }
        }

        &.e-split-bar-vertical .e-resize-handler {
            background: #0000 !important;

            &::before {
                content: "\e770" !important;
                transform: rotate(90deg);
            }
        }
    }
}

.e-grid {
    // For all intents and purposes, the grids need to
    // have overflow hidden.
    overflow: hidden;
    contain: content;
}

// Syncfusion, please center your damn handles.
.e-splitter {

    .e-splitter.e-splitter-horizontal .e-pane.e-pane-horizontal,
    .e-splitter.e-splitter-vertical .e-pane.e-pane-horizontal {
        contain: content;
    }

    .e-split-bar.e-split-bar-horizontal.e-split-bar-hover,
    .e-split-bar.e-split-bar-horizontal.e-split-bar-active,
    .e-split-bar.e-split-bar-vertical.e-split-bar-active,
    .e-split-bar.e-split-bar-vertical.e-split-bar-hover {

        .e-arrow-up,
        .e-arrow-down,
        .e-arrow-left,
        .e-arrow-right {
            background-color: var(--text-color) !important;
        }
    }
    .e-split-bar.e-split-bar-vertical .e-arrow-up {
        &::before {
            left: 11px !important
        }

        &::after {
            left: 12px !important
        }
    }
    .e-split-bar.e-split-bar-vertical .e-arrow-down {
        &::before {
            left: 13px !important
        }

        &::after {
            left: 12px !important
        }
    }
    .e-split-bar.e-split-bar-horizontal .e-navigate-arrow {

        &::before {
            top: 6px !important
        }
        &::after {
            top: 12px !important
        }
    }
}



/**
 * Oh dear.
 */
.e-grid,
.e-css,
.e-error,
.e-control,
.e-splitter.e-splitter-horizontal .e-pane,
.e-splitter.e-splitter-vertical .e-pane,
input.e-input,
textarea.e-input,
.e-input-group,
.e-input-group.e-control-wrapper,
.e-input-group.e-disabled,
.e-input-group.e-control-wrapper.e-disabled,
.e-float-input,
.e-float-input.e-control-wrapper,
.e-float-input.e-disabled,
.e-float-input.e-control-wrapper.e-disabled,
.e-float-input.e-input-group.e-disabled,
.e-float-input.e-input-group.e-control-wrapper.e-disabled,
.e-spinner-pane .e-spinner-inner .e-spin-label,
.e-popup.e-ddl .e-dropdownbase.e-nodata,
.e-popup.e-mention .e-dropdownbase.e-nodata,
.e-dropdownbase .e-list-item,
.e-dropdownbase .e-list-group-item,
.e-fixed-head,
.e-multi-column.e-ddl.e-popup.e-popup-open .e-ddl-header,
.e-ddl.e-popup.e-multiselect-group .e-list-group-item,
.e-btn,
.e-css.e-btn,
.e-checkbox-wrapper .e-label,
.e-css.e-checkbox-wrapper .e-label,
.e-tooltip-wrap .e-tip-content,
.e-control-wrapper.e-slider-container .e-scale,
.e-control-wrapper.e-slider-container .e-scale .e-tick .e-tick-value,
.e-dashboardlayout.e-control .e-panel .e-panel-header,
.e-radio+label .e-label,
.e-switch-wrapper .e-switch-on,
.e-switch-wrapper .e-switch-off,
.e-css.e-switch-wrapper .e-switch-on,
.e-css.e-switch-wrapper .e-switch-off,
.e-listview,
.e-control-wrapper.e-mask .e-maskedtextbox,
.e-upload.e-control-wrapper,
.e-bigger.e-small .e-upload.e-control-wrapper,
.e-treeview.e-drag-item,
.e-multi-select-wrapper input[type=text],
.e-multiselect.e-filled .e-multi-select-wrapper input[type=text],
.e-multi-select-wrapper .e-delim-values,
.e-multi-select-list-wrapper .e-selectall-parent .e-all-text,
.e-multi-select-wrapper .e-chips>.e-chipcontent,
.e-listbox-wrapper,
.e-listbox-container,
.e-splitter.e-splitter-horizontal .e-pane,
.e-splitter.e-splitter-vertical .e-pane,
.e-bigger .e-grid .e-row .e-input-group .e-input.e-field,
.e-bigger .e-grid .e-row .e-input-focus .e-input.e-field,
.e-grid.e-bigger .e-row .e-input-group .e-input.e-field,
.e-grid.e-bigger .e-row .e-input-focus .e-input.e-field,
.e-grid .e-row .e-input-group .e-input.e-field,
.e-grid .e-row .e-input-focus .e-input.e-field,
.e-rte-img-dialog.e-dialog.e-device.e-dlg-modal .e-linkheader,
.e-rte-audio-dialog.e-dialog.e-device.e-dlg-modal .e-linkheader,
.e-rte-video-dialog.e-dialog.e-device.e-dlg-modal .e-linkheader,
.e-dialog .e-img-uploadwrap .e-droptext,
.e-dialog .e-aud-uploadwrap .e-droptext,
.e-dialog .e-vid-uploadwrap .e-droptext,
.e-dialog.e-device .e-vid-uploadwrap .e-droptext,
.e-rte-table-popup .e-rte-popup-header,
.e-inplaceeditor .e-editable-value-wrapper .e-editable-value,
.e-inplaceeditor .e-editable-value-container .e-editable-value,
.e-inplaceeditor-tip.e-tooltip-wrap .e-tip-content .e-editable-title,
.e-message,
.e-pivot-calc-dialog-div .e-treeview ul li .e-list-text,
.e-pivotfieldlist-container .e-adaptive-field-list-dialog .e-member-editor-container ul li .e-list-text,
.e-pivotfieldlist-container .e-member-editor-dialog .e-member-editor-container ul li .e-list-text,
.e-pivotfieldlist-container .e-member-editor-dialog .e-select-all-container ul li .e-list-text,
.e-button-drag-clone,
.e-pivotfieldlist-container,
.e-pivotfieldlist-container .e-field-list-container .e-field-table .e-field-list ul li .e-list-text,
.e-pivotfieldlist-container.e-rtl .e-field-table .e-field-list ul li .e-list-text,
.e-pivotfieldlist-container.e-device .e-adaptive-container .e-content .e-pivot-calc-outer-div .e-radio-wrapper .e-label,
.e-pivotview .e-member-editor-dialog .e-select-all-container ul li .e-list-text,
.e-pivotview .e-member-editor-dialog .e-member-editor-container ul li .e-list-text,
.e-pivotview .e-member-editor-dialog .e-select-all-container ul li .e-list-text,
.e-pivotview .e-member-editor-dialog .e-member-editor-container ul li .e-list-text,
.e-pivottooltip p,
.e-query-builder .e-summary-text,
.e-tab .e-tab-header .e-toolbar-item .e-tab-text,
.e-spreadsheet .e-table tr .e-cell {
    font-family: "DT Flow", "Fira Sans", "Arial", sans-serif !important;
}


ejs-filemanager {
    height: 100% !important;

    .e-navigation {
        background: rgb(0 0 0 / 6%);
        box-shadow: 1.6px 0 3.6px 0 rgba(0, 0, 0, .132), 0.3px 0 .9px 0 rgba(0, 0, 0, .108);
        border-right-style: solid;
        border-width: 0 1px 0 0;
        border-color: rgb(0 0 0 / 16%)
    }

    .e-splitter {
        height: calc(100% - 42px) !important;

        .e-layout-content {
            overflow: hidden;
            max-height: 100%;

            .e-gridcontent {
                overflow-y: auto;
            }
        }
    }

    .e-address {
        .e-addressbar-ul {
            //padding: 4px 8px 9px 8px !important;

            .e-address-list-item {
                .e-icons {
                    margin: 16px 4px !important;
                }

                .e-list-text {
                    font-size: inherit !important;
                }
            }
        }
    }

    .e-large-icons {
        max-height: calc(100% - 42px) !important;
    }
}
