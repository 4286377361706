@use '@angular/material' as mat;
@include mat.core();

// ! This is a linter error
mat.$theme-ignore-duplication-warnings: true;

$dtflow-typography: mat.define-typography-config(
    $font-family: 'DT Flow',
);

$light-primary: mat.define-palette(mat.$blue-palette);
$light-accent: mat.define-palette(mat.$blue-grey-palette, A200, A100, A400);
$light-warn: mat.define-palette(mat.$red-palette);

$light-theme: mat.define-light-theme((
    color: (
        primary: $light-primary,
        accent: $light-accent,
        warn: $light-warn,
    ),
    typography: $dtflow-typography
));

$dark-primary: mat.define-palette(mat.$light-blue-palette, A200);
$dark-accent: mat.define-palette(mat.$light-blue-palette, A100);
$dark-warn: mat.define-palette(mat.$deep-orange-palette, 700, 500, 800);

$dark-theme: mat.define-dark-theme((
    color: (
        primary: $dark-primary,
        accent: $dark-accent,
        warn: $dark-warn,
    ),
    typography: $dtflow-typography
));

:root {
    --mdc-typography-body1-line-height: 30px;
    --mdc-typography-body1-font-size: 14px;
    --mdc-typography-button-letter-spacing: 0;
    --mdc-typography-body2-letter-spacing: 0;
    --mdc-typography-body1-letter-spacing: 0;
    --mdc-typography-subtitle2-letter-spacing: 0;
    --mdc-typography-subtitle1-letter-spacing: 0;
    --mdc-typography-headline6-letter-spacing: 0;
    --mdc-typography-caption-letter-spacing: 0;
}

@include mat.all-component-themes($dark-theme);

.light {
    @include mat.all-component-colors($light-theme);
}

// .mat-form-field-appearance-outline .mat-form-field-outline {
//   color: transparent;
// }

a, p, span {
    &>.mat-icon {
        vertical-align: middle;
    }
}

h1, h2, h3, h4, h5 {
    &>.mat-icon {
        vertical-align: text-top;
    }
}

.mat-tab-body-wrapper {
  height: 100%;
}

.mat-mdc-option {
    font-size: 12px !important;
    min-height: 32px !important;
}
.mat-mdc-optgroup-label {
    min-height: 32px !important;
    font-size: 12px !important;
    font-weight: bold !important;
    border-top: 1px solid #000 !important;
}

.clear .mat-dialog-container {
  background: #0000;
  padding: 0;
}

.mat-mdc-tab-group {
    height: 100%;
    width: 100%;
}
.mat-mdc-tab {
    flex-grow: 0!important;
}

.mat-mdc-tab-body-wrapper {
    flex: 1;
}
.mat-mdc-tab-body-content {
    overflow: hidden;
}


.mat-drawer-container {
    height: 100%;
}

.mat-expansion-panel-content {
    contain: content
}

.mdc-menu-surface {
    background-color: var(--dialog-background-color);
    color: var(--dialog-text-color)
}

mat-toolbar .mat-mdc-form-field {
    font-size: 12px;
    font-weight: 400;
    height: 32px;
    --mat-select-trigger-text-line-height: 32px;

    .mat-mdc-text-field-wrapper {
        min-height: 32px;
        .mat-mdc-form-field-flex .mat-mdc-floating-label {
            top: 14px;

            &.mdc-floating-label--float-above {
                top: 24px;
            }
        }
        .mdc-notched-outline__notch {
            width: auto !important;
            padding-right: 0;

            label {
                overflow: visible;
            }
        }
        &.mdc-text-field--focused {
            .mdc-notched-outline__notch {
                margin-right: -8px;
            }
        }
    }
    .mat-mdc-form-field-infix {
        padding: 0px !important;
        min-height: 24px;

        &>.mdc-icon-button {
            right: -16px;
            position: absolute;
            z-index: 100;
        }
    }
    .mat-mdc-input-element {
        height: 32px !important;
    }
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: #2a2a2a;
}
.light .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: #d5d5d5;
}

.mat-mdc-form-field-focus-overlay {
    background-color: #0000;
    border: 2px solid #046dbe;
}

.mdc-menu-surface.mat-mdc-select-panel {
    padding: 0px;
}

.mdc-button {
    .mdc-button__label {
        white-space: nowrap;
    }
}

.cdk-global-scrollblock {
    overflow-y: hidden;
}

.mat-drawer-content {
    background: var(--background-color);
}

button.mat-mdc-icon-button {
    &.mat-mdc-button-base {
        padding: 5px 0;
    }
    .mat-mdc-button-persistent-ripple {
        border-radius: 4px;
    }
    &.mat-mdc-button-base,
    span.mat-mdc-button-touch-target {
        width: 32px;
        height: 32px;
    }
}


.mat-toolbar-row,
.mat-toolbar-single-row {
    height: 48px;
}

.mat-mdc-tooltip-large .mdc-tooltip__surface {
    max-width: 400px;
}

.mdc-dialog__container {
    border-radius: 6px;
}


.mat-mdc-menu-panel {
    --mat-menu-item-label-text-size: 12px;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    min-height: 32px;
    //background: linear-gradient(180deg, #737373 6px, var(--menu-background-color) 6px);

    .mat-mdc-menu-content {
        font-size: 12px;

        .mat-mdc-menu-item {
            background: transparent; // var(--menu-background-color);
            height: 24px;
            min-height: 24px;
            max-height: 24px;
            padding-left: 0;
            width: 100% !important;
            white-space: nowrap;
            //transition: background-color 300ms ease-in-out;

            .mat-mdc-menu-item-text {
                padding-left: 16px;

                img {
                    height: 16px;
                    width:  16px;
                    margin: 0 8px;
                    filter: invert(1);
                    margin-left: -8px;
                }

                span {
                    font-size: 12px !important;
                }

                img + span {
                    padding-left: 0;
                }
            }

            &:hover {
                //background: var(--menu-hover-background-color);
            }

            .mdc-list-item__primary-text {
                white-space: nowrap;
                margin: 0;
            }
        }

        .mat-mdc-menu-item-highlighted {
            background: #cacbd3;
        }

        .separator {
            height: 1px;
            background: #888889;
            margin: 2px 16px 2px 38px;

            &+.separator {
                display: none;
            }
        }
    }
}

.mat-mdc-option .mdc-list-item__primary-text {
    display: contents;
}
