/**
 * Skeleton loaders should be no more than 600px wide!
 */
.skeleton {
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    background-color: var(--skeleton-background-color);

    &:after {
        content: "";
        position: absolute;
        inset: 0;
        // 8 18 33
        background: linear-gradient(to right,
            var(--skeleton-glare-background-color) 48%,
            var(--skeleton-glare-color) 53%,
            var(--skeleton-glare-background-color) 62%
        );
        background-repeat: repeat-y;
        background-size: 2400px 100px;
        animation: skeleton-glare 2s infinite ease-out;
    }
}

@keyframes skeleton-glare {
    from {
        background-position: -1800px 0;
    }

    to {
        background-position: -600px 0;
    }
}
