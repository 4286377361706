@import "@fontsource/poppins";
@import "@fontsource/material-icons-outlined";
@import "@fontsource/material-icons";

@font-face {
    font-family: "Digital";
    src: url(../assets/fonts/Segment7-4Gml.otf) format("opentype");
}

@font-face {
    font-family: "DT Flow";
    font-style: light;
    font-weight: 200;
    font-stretch: 100%;
    src: url(../assets/fonts/DTFlow-Light.woff2);
}

@font-face {
    font-family: "DT Flow";
    font-style: medium;
    font-weight: 300;
    font-stretch: 100%;
    src: url(../assets/fonts/DTFlow-Medium.woff2);
}

@font-face {
    font-family: "DT Flow";
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    src: url(../assets/fonts/DTFlow-Regular.woff2);
}

@font-face {
    font-family: "DT Flow";
    font-style: bold;
    font-weight: 500;
    font-stretch: 100%;
    src: url(../assets/fonts/DTFlow-Semibold.woff2);
}

@font-face {
    font-family: "DT Flow";
    font-weight: 500;
    font-stretch: 100%;
    src: url(../assets/fonts/DTFlow-Semibold.woff2);
}

@font-face {
    font-family: "DT Flow";
    font-style: bolder;
    font-weight: 600;
    font-stretch: 100%;
    src: url(../assets/fonts/DTFlow-Bold.woff2);
}

@font-face {
    font-family: "DT Mono";
    font-stretch: 100%;
    src: url(../assets/fonts/DTFlow-Monospace.woff2);
}

.f-digital {
    font-family: 'Digital', 'Courier New', Courier, monospace;
}

mat-icon.material-icons,
.material-icons-outlined {
    font-family: "Material Icons Outlined";
    font-size: 24px;
    font-weight: 400;
    font-style: normal;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
}



ngx-stackedit .editor {
    font-family: 'DT Flow' !important;
    .markdown-highlighting .code {
        font-family: 'DT Mono';
    }
}
