::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #1373c4;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
}

::-webkit-scrollbar-corner {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

ng-scrollbar.ng-scrollbar {
    --scrollbar-padding: 4px;
    --scrollbar-size: 8px;
    --scrollbar-border-radius: 04px;
    //--scrollbar-thumb-color: #0000;
    --scrollbar-thumb-color: #1373c4;
    --scrollbar-thumb-transition: height ease 200ms, width ease 200ms, background-color ease 200ms;

    &:hover {
       // --scrollbar-thumb-color: #444;
    }
}
.light ng-scrollbar.ng-scrollbar:hover {
//    --scrollbar-thumb-color: #999;
}
