@mixin light-theme() {
    --semi-background-color: #ffffff70;
    --background-color: #e1e2eb;
    --dim-background-color: #f1f1f1;
    --panel-background-color: #fff;
    --border-color: #000;

    --text-color: #212121;
    --text-subdued-color: rgba(0, 0, 0, 0.54);
    --text-header-color: #0d47a1;
    --text-muted-color: #5a5a5a;
    --link-text-color: #0d47a1;

    --menu-background: linear-gradient(180deg, #818181, #e1e2eb 32px);
    --menu-background-color: #e1e2eb;
    --menu-hover-background-color: #cacbd3;

    --table-row-alt-background-color: #efefef;
    --table-row-hover-background-color: #fff;
    --table-row-selected-background-color: #ddf;
    --table-row-active-background-color: #e0e0e0;
    --table-header-background-color: #f4f5f9;
    --table-header-border-color: #f4f5f9;
    --table-row-border-color: #ccc;
    --table-background-color: #f6f6f6;
    --table-row-selected-hover-background-color: #82b3ff;

    --list-row-hover-background-color: #cacbd3;
    --list-row-special-hover-background-color: #c0ddf2;

    --input-background-color: #fff;

    --dialog-background-color: #fff;
    --dialog-text-color: #000;
    --dialog-dark-background-color: #fff;
    --dialog-dark-text-color: #fff;
    --dialog-titlebar-background-color: #363636;
    --dialog-titlebar-text-color: #efefef;

    --tooltip-border-color: #212121;
    --tooltip-header-text-color: #fff;
    --tooltip-inner-text-color: var(--text-color);
    --tooltip-inner-background-color: var(--background-color);

    --skeleton-background-color: #dedede;
    --skeleton-glare-background-color: #c3d2dc22;
    --skeleton-glare-color: #ffffff44;

    --splitter-background-color: var(--primaryThemeColor);
    --splitter-hover-background-color: var(--primaryThemeColor);

    // Progress spinner color
    --mdc-circular-progress-active-indicator-color: #0d47a1;
    --mdc-dialog-container-color: #0000;

    // General palette colors
    --palette-amber-background-color: #ffc107;
    --palette-amber-color: #121212;
    --palette-green-background-color: #4caf50;
    --palette-green-color: #fff;
    --palette-red-background-color: #f44336;
    --palette-red-color: #fff;
    --palette-orange-background-color: #ff8c47;
    --palette-orange-color: #121212;
    --palette-blue-background-color: #1976d2;
    --palette-blue-color: #121212;
    --palette-yellow-background-color: #ffeb3b;
    --palette-yellow-color: #121212;
    --palette-gray-background-color: #8d8d8d;
    --palette-gray-color: #fff;


    --json-base-text-color: #000;
    --json-base-background-color: #fff;
    --json-string-text-color: #a31515;
    --json-boolean-text-color: #0000ff;
    --json-null-text-color: #0000ff;
    --json-number-text-color: #098658;
    --json-key-text-color: #0451a5;

    // Theme colors
    --color-positive-400: #0dfdb5;
    --color-positive-600: #018d63;
    --color-secondary-200: #f3ebff;
    --color-secondary-250: #e8d6ff;
    --color-secondary-300: #dcc2ff;
    --color-secondary-350: #c599ff;
    --color-secondary-400: #ae70ff;
    --color-secondary-450: #9747ff;
    --color-secondary-500: #7f1aff;
    --color-secondary-550: #6100e0;
    --color-secondary-600: #5000b8;
    --color-secondary-650: #3e008f;
    --color-secondary-700: #35007a;
    --color-secondary-750: #2c0066;
    --color-secondary-800: #230052;

    // Context Menu coloring
    --ngx-menu-text-color: #333;
    --ngx-menu-disabled-text-color: #666;
    --ngx-menu-background-color: #d1d1d1;
    --ngx-menu-hover-background-color: #81d4fa;
    --ngx-menu-hover-text-color: #000;
    --ngx-menu-shortcut-text-color: #444;
    --ngx-menu-separator-color: #a3a3a3;
    --ngx-tooltip-background-color: #fdfdfd;

    /**
     * Material styles
     */
    --mdc-dialog-container-color: #1f4279;
    --dialog-shadow-color: #000;
    --mdc-list-list-item-hover-state-layer-opacity: 0.05;

    .mat-mdc-dialog-container {
        --mdc-dialog-header-color: #5e5e5e;
        color: #fff;
		--mdc-dialog-tab-panel-color: #f4f4f4;
        --mdc-dialog-container-color: #efefef;
        --mdc-dialog-container-border-color: #fff;
    }
    .dialog-dark .mat-mdc-dialog-surface {
        color: #fff;
    }

    .e-popup.e-ddl {
        box-shadow: 0px 3px 9px 0px #00000080 !important;
    }

    --hover-background-color: #aacbe3;
    --selected-background-color: #a3a3a3;
    --grid-background-color:#aacbe3;

    ejs-grid {
        background-color: var(--background-color);

        .e-row.e-altrow {
            background-color: #efefef;
            --background-color: #efefef;
        }
    }

    .tag-basic {        --text-color: adjust-hue(invert(rgb(248, 197, 201), 180)); --color: rgb(231, 72, 86);   --background-color: rgba(231, 65, 80, 0.24);   --background-hover-color: rgba(231, 65, 80, 0.38)   }
    .tag-red {          --text-color: adjust-hue(invert(rgb(248, 197, 201), 180)); --color: rgb(231, 72, 86);   --background-color: rgba(231, 65, 80, 0.24);   --background-hover-color: rgba(231, 65, 80, 0.38)   }
    .tag-orange {       --text-color: adjust-hue(invert(rgb(255, 220, 179), 180)); --color: rgb(255, 140, 0);   --background-color: rgba(255, 140, 0, 0.24);   --background-hover-color: rgba(255, 140, 0, 0.38)   }
    .tag-peach {        --text-color: adjust-hue(invert(rgb(255, 230, 199), 180)); --color: rgb(255, 171, 69);  --background-color: rgba(255, 168, 61, 0.24);  --background-hover-color: rgba(255, 168, 61, 0.38)  }
    .tag-yellow {       --text-color: adjust-hue(invert(rgb(255, 251, 179), 180)); --color: rgb(255, 241, 0);   --background-color: rgba(255, 241, 0, 0.24);   --background-hover-color: rgba(255, 241, 0, 0.38)   }
    .tag-green {        --text-color: adjust-hue(invert(rgb(193, 241, 191), 180)); --color: rgb(71, 208, 65);   --background-color: rgba(65, 208, 59, 0.24);   --background-hover-color: rgba(65, 208, 59, 0.38)   }
    .tag-light-teal {   --text-color: adjust-hue(invert(rgb(185, 238, 240), 180)); --color: rgb(48, 198, 204);  --background-color: rgba(42, 198, 204, 0.24);  --background-hover-color: rgba(42, 198, 204, 0.38)  }
    .tag-olive {        --text-color: adjust-hue(invert(rgb(207, 230, 175), 180)); --color: rgb(115, 170, 36);  --background-color: rgba(113, 170, 31, 0.24);  --background-hover-color: rgba(113, 170, 31, 0.38)  }
    .tag-blue {         --text-color: adjust-hue(invert(rgb(176, 234, 251), 180)); --color: rgb(0, 188, 242);   --background-color: rgba(0, 188, 242, 0.24);   --background-hover-color: rgba(0, 188, 242, 0.38)   }
    .tag-purple {       --text-color: adjust-hue(invert(rgb(215, 202, 234), 180)); --color: rgb(135, 100, 184); --background-color: rgba(132, 95, 184, 0.24);  --background-hover-color: rgba(132, 95, 184, 0.38)  }
    .tag-pink {         --text-color: adjust-hue(invert(rgb(252, 222, 235), 180)); --color: rgb(244, 149, 191); --background-color: rgba(244, 142, 187, 0.24); --background-hover-color: rgba(244, 142, 187, 0.38) }
    .tag-steel-light {  --text-color: adjust-hue(invert(rgb(225, 230, 232), 180)); --color: rgb(160, 174, 178); --background-color: rgba(155, 173, 178, 0.24); --background-hover-color: rgba(155, 173, 178, 0.38) }
    .tag-steel-gray {   --text-color: adjust-hue(invert(rgb(145, 194, 207), 180)); --color: rgb(0, 75, 96);     --background-color: rgba(0, 75, 96, 0.24);     --background-hover-color: rgba(0, 75, 96, 0.38)     }
    .tag-light-gray {   --text-color: adjust-hue(invert(rgb(232, 230, 229), 180)); --color: rgb(177, 173, 171); --background-color: rgba(177, 170, 166, 0.24); --background-hover-color: rgba(177, 170, 166, 0.38) }
    .tag-gray {         --text-color: adjust-hue(invert(rgb(206, 204, 203), 180)); --color: rgb(93, 90, 88);    --background-color: rgba(93, 88, 85, 0.24);    --background-hover-color: rgba(93, 88, 85, 0.38)    }
    .tag-black {        --text-color: adjust-hue(invert(rgb(179, 179, 179), 180)); --color: rgb(0, 0, 0);       --background-color: rgba(0, 0, 0, 0.24);       --background-hover-color: rgba(0, 0, 0, 0.38)       }
    .tag-dark-red {     --text-color: adjust-hue(invert(rgb(233, 199, 205), 180)); --color: rgb(117, 11, 28);   --background-color: rgba(117, 8, 25, 0.24);    --background-hover-color: rgba(117, 8, 25, 0.38)    }
    .tag-dark-orange {  --text-color: adjust-hue(invert(rgb(239, 196, 173), 180)); --color: rgb(202, 80, 16);   --background-color: rgba(202, 76, 10, 0.24);   --background-hover-color: rgba(202, 76, 10, 0.38)   }
    .tag-brown {        --text-color: adjust-hue(invert(rgb(230, 200, 166), 180)); --color: rgb(171, 98, 13);   --background-color: rgba(171, 96, 8, 0.24);    --background-hover-color: rgba(171, 96, 8, 0.38)    }
    .tag-dark-yellow {  --text-color: adjust-hue(invert(rgb(236, 223, 165), 180)); --color: rgb(193, 156, 0);   --background-color: rgba(193, 156, 0, 0.24);   --background-hover-color: rgba(193, 156, 0, 0.38)   }
    .tag-dark-green {   --text-color: adjust-hue(invert(rgb(141, 201, 163), 180)); --color: rgb(0, 75, 28);     --background-color: rgba(0, 75, 28, 0.24);     --background-hover-color: rgba(0, 75, 28, 0.38)     }
    .tag-dark-teal {    --text-color: adjust-hue(invert(rgb(142, 199, 203), 180)); --color: rgb(0, 75, 80);     --background-color: rgba(0, 75, 80, 0.24);     --background-hover-color: rgba(0, 75, 80, 0.38)     }
    .tag-dark-olive {   --text-color: adjust-hue(invert(rgb(154, 210, 154), 180)); --color: rgb(11, 106, 11);   --background-color: rgba(8, 106, 8, 0.24);     --background-hover-color: rgba(8, 106, 8, 0.38)     }
    .tag-dark-blue {    --text-color: adjust-hue(invert(rgb(191, 205, 227), 180)); --color: rgb(0, 32, 80);     --background-color: rgba(0, 32, 80, 0.24);     --background-hover-color: rgba(0, 32, 80, 0.38)     }
    .tag-dark-purple {  --text-color: adjust-hue(invert(rgb(212, 200, 229), 180)); --color: rgb(50, 20, 90);    --background-color: rgba(49, 17, 90, 0.24);    --background-hover-color: rgba(49, 17, 90, 0.38)    }
    .tag-dark-magenta { --text-color: adjust-hue(invert(rgb(229, 192, 229), 180)); --color: rgb(92, 0, 92);     --background-color: rgba(92, 0, 92, 0.24);     --background-hover-color: rgba(92, 0, 92, 0.38)     }

    app-dialog-wrapper-basic,
    .modal {
        --dialogBackground: #e1e2eb;
    }

    app-file-storage,
    app-filestorage-dialog,
    app-asset-documentation,
    app-markdown-editor,
    app-note-editor,
    app-notes,
    app-topics,
    app-property-sheet {
        --dialogBackground: #e1e2eb;
        --textAreaBackground: #fff;
        --dropshadowColor: rgb(0 0 0 / 50%);
        --background: #e1e2eb;
        --authorColor: #333;
        --dateColor: #333;
        --notesHeaderTextColor: #ffffff;
        --notesHeaderBackground: #5e5e5e;
        --notesSubtitleBackground: #ffffff1f;
        --bubbleBackground: #ffffff;
        --bubbleTextColor: #000;
        --bubbleBackgroundInternal: #f0b6a6;
        --visibilityInternalTextColor: #FF0000;
        --visibilityTextColor: #006dff;
        --visibilityTextColorUnselected: #dddddd;
        --visibilityBackground: rgba(128, 128, 128, 0.5);
        --visibilitySelectedBackground: #c56767;
        --textAreaColor: #000;
        --textAreaBackground: #FFF;
        --textAreaBackgroundInternal: #f5e0da;
        --notesEditorBackground: rgb(182, 185, 186);
        --dividerColor: #3d3d3d;
        --button-background-color: #2196f3;
        --button-label-text-color: white;
        --button-hover-backgroud-color: rgba(255, 255, 255, 0.1215686275);
        --icon-button-hover-bg-color: rgba(0, 0, 0, 0.122);
    }

    app-filemanager {
        --gutter-color: #ccc;
        --filemanager-sidebar-background-color: #efefef;
        --filemanager-background-color: #ffffff;
        --filemanager-row-alt-background-color: #dfdfdf;
        --filemanager-row-hover-background-color: #fff;
        --filemanager-tab-background-color: #eee;

        --filemanager-breadcrumb-border-color: #999;
        --filemanager-breadcrumb-border-bottom-color: #666;
        --filemanager-breadcrumb-background: #f0f0f0;
        --filemanager-breadcrumb-color: #232323;
        --filemanager-breadcrumb-button-hover-background-color: #ccc;
        --filemanager-breadcrumb-button-hover-color: #000;
    }
}
