
@import "./material.scss";
@import "./fonts.scss";
@import "./dialogs.scss";
@import "./syncfusion.scss";

@import "./themes/dark.scss";
@import "./themes/light.scss";
@import "./dto-styles.scss";
@import "./print.scss";
@import "./scrollbar.scss";
@import "./global.scss";
@import "./ngx-menu.scss";
@import "./profile-image.scss";
@import "./rag-colors.scss";
@import "./skeleton.scss";
@import "./splitter.scss";

.light {
    @include light-theme;
}
.dark {
    @include dark-theme;
}

// Implicit theme
// @media (prefers-color-scheme: dark) {
//     :root {
//         @include dark-theme;
//     }
// }
// @media (prefers-color-scheme: light) {
//     :root {
//         @include light-theme;
//     }
// }


// Apply a flex-grow
.content-stage > ngx-lazy-loader > * {
    display: flex;
    flex: 1;
    overflow: hidden;
}

.exit {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
}

.mobile {
    .auto {
        max-width: 100vw !important;

        &.active {
            max-height: calc(100vh - 64px) !important;

            .items {
                max-height: calc(100vh - 152px) !important;
            }
        }
    }
    .tenantId {
        max-width: unset !important;
    }
}



// TODO: Set these as defaults, without global overrides.
.eos.dark app-dashboard .mat-drawer-container>.mat-drawer-content,
.dt-gradient {
    background: radial-gradient(#55287a, rgba(85, 40, 122, 0.8), #0000, #035e9f7d);
    background-color: #000;
    background-position: -200% -100%;
    background-size: 300% 200%;
    background-repeat: repeat;
}
.eos app-dashboard .mat-drawer-container>.mat-drawer-content {
    background-image: linear-gradient(#342144, #9d72bb);
    background-size: cover;
}
.eom app-dashboard .mat-drawer-container>.mat-drawer-content {
    background-image: linear-gradient(#294421, #9dbb72);
    background-size: cover;
}
.eob app-dashboard .mat-drawer-container>.mat-drawer-content {
    background-image: linear-gradient(#213444, #729dbb);
    background-size: cover;
}

.auto .items {
    // background-color: #353535!important;
    border-color: #444;
}

.welcome-panel .panel.glassy {
    background-color: #242424;
    img {
        height: unset;
        max-width: 500px;
    }
}

