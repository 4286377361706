:root {
    --ngx-menu-font-size: 12px
}

.ngx-menu ngx-menu {
    // --ngx-menu-hover-background-color: var(--list-row-special-hover-background-color);
    // --ngx-menu-text-color: var(--text-color);
    // --ngx-menu-separator-color: var(--text-muted-color);

    tr {
        &:hover {
            transition: background-color 100ms ease;
        }

        td {
            img, mat-icon {
                transform: translate(-4px, 2px) !important;
                font-size: 16px;
                width: 16px;
                height: 16px;
            }
        }
    }

    tr.disabled {
        opacity: 0.4 !important;
    }
    tr.separator {
        opacity: 1 !important;

        hr {
           margin: 0 8px;
        }
    }

    .external-link {
        font-size: 16px;
        width: 16px;
        height: 16px;
    }

    a {
        color: var(--text-color);
    }
}

ngx-stackedit .editor {
    *::selection {
        background-color: #3a3d41 !important;
    }

    *:focus *::selection {
        background-color: #264f78 !important;
    }
}
.light ngx-stackedit .editor {
    *::selection {
        background-color: #e5ebf1 !important;
    }

    *:focus *::selection {
        background-color: #add6ff !important;
    }
}

.light ngx-command-palette {
    background-color: #f8f8f8 !important;
    .commands .command.selected {
        background-color: #e8e8e8 !important;
    }
    .commands .command:hover {
        background-color: #f2f2f2 !important;
    }
    .command {
        .label {
            color: #121212 !important;
        }
        .key {
            color: #121212 !important;
            background-color: #ededed !important;
        }
    }
}
