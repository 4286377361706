@mixin print {
    html {
        overflow: auto;
    }

    body {
        height: unset;
        background-color: var(--background-color);
    }

    .nav,
    .toolbar {
        display: none !important
    }

    app-root {
        contain: unset !important;
    }

    body,
    .main,
    .main>.content,
    .main>.content>.page-content,
    .main>.content>.page-content>.content-stage {
        overflow: visible !important
    }

    .content-stage>ngx-lazy-loader>* {
        overflow: visible;
        height: 100%
    }

    .print-hidden {
        display: none;
    }
}

@media print {
    @include print();
}

html.print {
    @include print();
}
