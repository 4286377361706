
@mixin dark-theme() {

    --semi-background-color: #00000070;
    --background-color: #121212;
    --dim-background-color: #161616;
    --panel-background-color: #1e1e1e;
    --border-color: #6c6c6c;

    --text-color: #dfdfdf;
    --text-bright-color: #fff;
    --text-subdued-color: #aaa;
    --text-muted-color: #a4a4a4;
    --link-text-color: #81d4fa;

    --menu-background: linear-gradient(180deg, #818181, #e1e2eb 32px);
    --menu-background-color: #212121;
    --menu-hover-background-color: #272727;

    --table-background-color: #1b1a19;
    --table-row-border-color: #292827;
    --table-row-selected-background-color: #214f61;
    --table-header-border-color: #555;
    --table-row-alt-background-color: #282828;
    --table-row-hover-background-color: #363636;
    --table-row-active-background-color: #3c3c3c;
    --table-header-background-color: #212121;

    --list-row-special-hover-background-color: #6fc2ff;

    --input-background-color: #212121;

    --dialog-background-color: #333;
    --dialog-text-color: #ddd;
    --dialog-dark-background-color: #fff;
    --dialog-dark-text-color: #fff;
    --dialog-titlebar-background-color: #363636;
    --dialog-titlebar-text-color: #efefef;
    --dialog-shadow-color: #fff;

    --tooltip-border-color: #d4d4d4;
    --tooltip-header-text-color: #000;
    --tooltip-inner-text-color: var(--text-color);
    --tooltip-inner-background-color: var(--background-color);

    --skeleton-background-color: #25282a;
    --skeleton-glare-background-color: #25282a22;
    --skeleton-glare-color: #bbbbbb44;

    --splitter-background-color: var(--primaryThemeColor);
    --splitter-hover-background-color: var(--primaryThemeColor);

    --palette-amber-background-color: #ffc107;
    --palette-amber-color: #121212;
    --palette-green-background-color: #4caf50;
    --palette-green-color: #fff;
    --palette-red-background-color: #f44336;
    --palette-red-color: #fff;
    --palette-orange-background-color: #ff8c47;
    --palette-orange-color: #121212;
    --palette-blue-background-color: #1976d2;
    --palette-blue-color: #121212;
    --palette-yellow-background-color: #ffeb3b;
    --palette-yellow-color: #121212;
    --palette-gray-background-color: #8d8d8d;
    --palette-gray-color: #fff;

    --json-base-text-color: #fff;
    --json-base-background-color: #1e1e1e;
    --json-string-text-color: #ce9178;
    --json-boolean-text-color: #569cd6;
    --json-null-text-color: #569cd6;
    --json-number-text-color: #b5cea8;
    --json-key-text-color: #9cdcfe;

    --color-positive-400: #0dfdb5;
    --color-positive-600: #018d63;
    --color-secondary-800: #f3ebff;
    --color-secondary-850: #e8d6ff;
    --color-secondary-700: #dcc2ff;
    --color-secondary-750: #c599ff;
    --color-secondary-600: #ae70ff;
    --color-secondary-650: #9747ff;
    --color-secondary-500: #7f1aff;
    --color-secondary-550: #6100e0;
    --color-secondary-400: #5000b8;
    --color-secondary-450: #3e008f;
    --color-secondary-300: #35007a;
    --color-secondary-350: #2c0066;
    --color-secondary-200: #230052;


    --mdc-circular-progress-active-indicator-color: #fff;
    --mdc-protected-button-disabled-label-text-color: #ccc;
    --mdc-dialog-container-color: #0000;

    .mat-mdc-dialog-container {
        --mdc-dialog-header-color: #2c2c2c;
        --mdc-dialog-container-color: #181818;
        --mdc-dialog-container-border-color: #444;
		--mdc-dialog-tab-panel-color: #1f1f1f;
    }

    .mat-mdc-button:not(:disabled) {
        --mdc-text-button-label-text-color: var(--dialog-dark-text-color);
    }

    --hover-background-color: #5caddd;
    --selected-background-color: #797b7c;
    --grid-background-color:#5caddd;

    ejs-grid .e-row.e-altrow {
        background-color: #212121;
        --background-color: #212121;
    }

    app-dialog-wrapper-basic,
    .modal {
        --dialogBackground: #252423;
    }

    app-file-storage,
    app-filestorage-dialog,
    app-note-editor,
    app-markdown-editor,
    app-property-sheet,
    app-asset-documentation,
    app-topics,
    app-notes {
        --dialogBackground: #252423;
        --textAreaBackground: #404040;
        --dropshadowColor: rgba(0 0 0);
        --background: #353535;
        --authorColor: #e9e9e9;
        --dateColor: #e9e9e9;
        --notesHeaderTextColor: #ffffff;
        --notesHeaderBackground: #1b1a19;
        --notesSubtitleBackground: #252423;
        --bubbleBackground: #9bd2ff; // #70b7f0;
        --bubbleTextColor: #000;
        --bubbleBackgroundInternal: #f0b6a6;
        --visibilityInternalTextColor: #ff3e3e;
        --visibilityTextColor: #82b7ff;
        --visibilityTextColorUnselected: #999999;
        --visibilityBackground: rgba(128, 128, 128, 0.5);
        --visibilitySelectedBackground: #c32a2a;
        --textAreaColor: #d4d4d4;
        --textAreaBackground: #4d4d4c;
        --textAreaBackgroundInternal: #532416;
        --notesEditorBackground: #1b1a19;
        --dividerColor: #d3d3d3;
        --button-hover-backgroud-color: rgba(255, 255, 255, 0.1215686275);
        --icon-button-hover-bg-color: rgba(255, 255, 255, 0.1215686275);

        .Header {
            .title {
                img {
                    filter: invert(1);
                }
            }
        }
    }

    .dialog-allocation-entry {
        .mat-mdc-dialog-container .mdc-dialog__surface {
            --mdc-dialog-container-color: #353535b3;
        }
        table {
            background-color: #353535;
        }
    }

    .e-popup.e-ddl {
        box-shadow: 0px 3px 9px 0px #ffffff60 !important;
    }

    app-asset-navigator .NavigationIcon,
    app-asset-list .asset .icon,
    app-data-filter img {
        filter: invert(1) hue-rotate(180deg);
    }

    // These are things that the palette doesn't cover well for dark mode
    .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
    .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
        color: #81d4fa;
    }


    .tag-basic {        --text-color: #fff;               --color: #666;               --background-color: unset;                       --background-hover-color: unset                     }
    .tag-red {          --text-color: rgb(248, 197, 201); --color: rgb(231, 72, 86);   --background-color: rgba(231, 65, 80, 0.24);   --background-hover-color: rgba(231, 65, 80, 0.38)   }
    .tag-orange {       --text-color: rgb(255, 220, 179); --color: rgb(255, 140, 0);   --background-color: rgba(255, 140, 0, 0.24);   --background-hover-color: rgba(255, 140, 0, 0.38)   }
    .tag-peach {        --text-color: rgb(255, 230, 199); --color: rgb(255, 171, 69);  --background-color: rgba(255, 168, 61, 0.24);  --background-hover-color: rgba(255, 168, 61, 0.38)  }
    .tag-yellow {       --text-color: rgb(255, 251, 179); --color: rgb(255, 241, 0);   --background-color: rgba(255, 241, 0, 0.24);   --background-hover-color: rgba(255, 241, 0, 0.38)   }
    .tag-green {        --text-color: rgb(193, 241, 191); --color: rgb(71, 208, 65);   --background-color: rgba(65, 208, 59, 0.24);   --background-hover-color: rgba(65, 208, 59, 0.38)   }
    .tag-light-teal {   --text-color: rgb(185, 238, 240); --color: rgb(48, 198, 204);  --background-color: rgba(42, 198, 204, 0.24);  --background-hover-color: rgba(42, 198, 204, 0.38)  }
    .tag-olive {        --text-color: rgb(207, 230, 175); --color: rgb(115, 170, 36);  --background-color: rgba(113, 170, 31, 0.24);  --background-hover-color: rgba(113, 170, 31, 0.38)  }
    .tag-blue {         --text-color: rgb(176, 234, 251); --color: rgb(0, 188, 242);   --background-color: rgba(0, 188, 242, 0.24);   --background-hover-color: rgba(0, 188, 242, 0.38)   }
    .tag-purple {       --text-color: rgb(215, 202, 234); --color: rgb(135, 100, 184); --background-color: rgba(132, 95, 184, 0.24);  --background-hover-color: rgba(132, 95, 184, 0.38)  }
    .tag-pink {         --text-color: rgb(252, 222, 235); --color: rgb(244, 149, 191); --background-color: rgba(244, 142, 187, 0.24); --background-hover-color: rgba(244, 142, 187, 0.38) }
    .tag-steel-light {  --text-color: rgb(225, 230, 232); --color: rgb(160, 174, 178); --background-color: rgba(155, 173, 178, 0.24); --background-hover-color: rgba(155, 173, 178, 0.38) }
    .tag-steel-gray {   --text-color: rgb(145, 194, 207); --color: rgb(0, 75, 96);     --background-color: rgba(0, 75, 96, 0.24);     --background-hover-color: rgba(0, 75, 96, 0.38)     }
    .tag-light-gray {   --text-color: rgb(232, 230, 229); --color: rgb(177, 173, 171); --background-color: rgba(177, 170, 166, 0.24); --background-hover-color: rgba(177, 170, 166, 0.38) }
    .tag-gray {         --text-color: rgb(206, 204, 203); --color: rgb(93, 90, 88);    --background-color: rgba(93, 88, 85, 0.24);    --background-hover-color: rgba(93, 88, 85, 0.38)    }
    .tag-black {        --text-color: rgb(179, 179, 179); --color: rgb(0, 0, 0);       --background-color: rgba(0, 0, 0, 0.24);       --background-hover-color: rgba(0, 0, 0, 0.38)       }
    .tag-dark-red {     --text-color: rgb(233, 199, 205); --color: rgb(117, 11, 28);   --background-color: rgba(117, 8, 25, 0.24);    --background-hover-color: rgba(117, 8, 25, 0.38)    }
    .tag-dark-orange {  --text-color: rgb(239, 196, 173); --color: rgb(202, 80, 16);   --background-color: rgba(202, 76, 10, 0.24);   --background-hover-color: rgba(202, 76, 10, 0.38)   }
    .tag-brown {        --text-color: rgb(230, 200, 166); --color: rgb(171, 98, 13);   --background-color: rgba(171, 96, 8, 0.24);    --background-hover-color: rgba(171, 96, 8, 0.38)    }
    .tag-dark-yellow {  --text-color: rgb(236, 223, 165); --color: rgb(193, 156, 0);   --background-color: rgba(193, 156, 0, 0.24);   --background-hover-color: rgba(193, 156, 0, 0.38)   }
    .tag-dark-green {   --text-color: rgb(141, 201, 163); --color: rgb(0, 75, 28);     --background-color: rgba(0, 75, 28, 0.24);     --background-hover-color: rgba(0, 75, 28, 0.38)     }
    .tag-dark-teal {    --text-color: rgb(142, 199, 203); --color: rgb(0, 75, 80);     --background-color: rgba(0, 75, 80, 0.24);     --background-hover-color: rgba(0, 75, 80, 0.38)     }
    .tag-dark-olive {   --text-color: rgb(154, 210, 154); --color: rgb(11, 106, 11);   --background-color: rgba(8, 106, 8, 0.24);     --background-hover-color: rgba(8, 106, 8, 0.38)     }
    .tag-dark-blue {    --text-color: rgb(191, 205, 227); --color: rgb(0, 32, 80);     --background-color: rgba(0, 32, 80, 0.24);     --background-hover-color: rgba(0, 32, 80, 0.38)     }
    .tag-dark-purple {  --text-color: rgb(212, 200, 229); --color: rgb(50, 20, 90);    --background-color: rgba(49, 17, 90, 0.24);    --background-hover-color: rgba(49, 17, 90, 0.38)    }
    .tag-dark-magenta { --text-color: rgb(229, 192, 229); --color: rgb(92, 0, 92);     --background-color: rgba(92, 0, 92, 0.24);     --background-hover-color: rgba(92, 0, 92, 0.38)     }
}
